import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '@/views/Dashboard';
import AuthLogin from '@/views/Auth/Login';
import AuthForgotPassword from '@/views/Auth/ForgotPassword';
import AuthResetPassword from '@/views/Auth/ResetPassword';
import store from "@/store";

Vue.use(VueRouter);
const routes = [
    {
        path: '/auth/login',
        name: 'auth.login',
        component: AuthLogin,
        meta: {
            middleware: "guest",
            breadcrumb: 'Login',
        },
    },{
        path: '/auth/forgot-password',
        name: 'auth.forgot-password',
        component: AuthForgotPassword,
        meta: {
            middleware: "guest",
            breadcrumb: 'Forgot Password',
        },
    },{
        path: '/auth/reset-password/:token',
        name: 'auth.reset-password',
        component: AuthResetPassword,
        meta: {
            middleware: "guest",
            breadcrumb: 'Reset Password',
        },
    },{
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            middleware: "auth",
            breadcrumb: 'Dashboard',
        },
        redirect: {
            name: 'dashboard.home'
        },
        children: [
            {
                path: '/dashboard/home',
                name: 'dashboard.home',
                component: () => import('@/views/Home'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Home',
                },
            },{
                path: '/dashboard/faqs',
                name: 'dashboard.faqs',
                component: () => import('@/views/Dashboard/Faqs/Index'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'FAQs',
                },
            },{
                path: '/dashboard/products',
                name: 'dashboard.products.index',
                component: () => import('@/views/Dashboard/Products/Index'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Products',
                },
            },{
                path: '/dashboard/orders',
                name: 'dashboard.orders.index',
                component: () => import('@/views/Dashboard/Orders/Index'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Orders',
                }
            },{
                path: '/dashboard/orders/:id',
                name: 'dashboard.orders.edit',
                component: () => import('@/views/Dashboard/Orders/Edit'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Order Edit',
                }
            },{
                path: '/dashboard/forms',
                name: 'dashboard.forms',
                component: () => import('@/views/Dashboard/Forms/Index'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Forms',
                }
            },{
                path: '/dashboard/schools',
                name: 'dashboard.schools',
                component: () => import('@/views/Dashboard/Schools/Index'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Schools',
                }
            },{
                path: '/dashboard/schools/:id',
                name: 'dashboard.schools.edit',
                component: () => import('@/views/Dashboard/Schools/Edit'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'School',
                }
            },{
                path: '/dashboard/houses',
                name: 'dashboard.houses',
                component: () => import('@/views/Dashboard/Houses/Index'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Houses',
                }
            },{
                path: '/dashboard/discount-offers',
                name: 'dashboard.discount_offers',
                component: () => import('@/views/Dashboard/DiscountOffer/Index'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Discount Offers',
                }
            },{
                path: '/dashboard/packs',
                name: 'dashboard.packs',
                component: () => import('@/views/Dashboard/Packs/Index'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Packs',
                }
            },{
                path: '/dashboard/packs/:id',
                name: 'dashboard.packs.edit',
                component: () => import('@/views/Dashboard/Packs/Edit'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Pack',
                }
            },{
                path: '/dashboard/vouchers',
                name: 'dashboard.vouchers',
                component: () => import('@/views/Dashboard/Vouchers/Index'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Vouchers',
                }
            },{
                path: '/dashboard/gift-cards',
                name: 'dashboard.gift-cards',
                component: () => import('@/views/Dashboard/GiftCards/Index'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Gift Cards',
                }
            },{
                path: '/dashboard/gift-card-transactions',
                name: 'dashboard.gift-card-transactions',
                component: () => import('@/views/Dashboard/GiftCardTransactions/Index'),
                meta: {
                    middleware: "auth",
                    breadcrumb: 'Gift Card Transactions',
                }
            },
        ],
    },{
        path: '/',
        redirect: {
            name: 'dashboard.home'
        }
    },{
        path: '/app',
        redirect: {
            name: 'dashboard.home'
        }
    }
];

let router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    let siteName = process.env.MIX_APP_NAME ?? 'QFConnector';
    document.title = `${to.meta.breadcrumb} – ${siteName}`
    if(to.meta.middleware === "guest") {
        if(store.state.auth.authenticated) {
            next({name: "dashboard.home" });
        }
        next();
    } else {
        if(store.state.auth.authenticated) {
            next();
        } else {
            next({name: "auth.login" });
        }
    }
})

export default router;
