import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist'
import auth from './modules/auth';
import ACTION_TYPES from "./action-types";
import MUTATION_TYPES from "./mutation-types";
import { v4 as uuidv4 } from 'uuid';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        new VuexPersistence({
            reducer: rootState => ({
                auth: rootState.auth,
            }),
        }).plugin,
    ],
    modules: {
        auth,
    },
    state: {
        alerts: [],
        navItems: [
            { title: 'FAQs', icon: 'mdi-frequently-asked-questions', link: {name: 'dashboard.faqs'}, permission: 'FAQ_READ_WEB' },
            { title: 'Schools', icon: 'mdi-school', link: {name: 'dashboard.schools'}, permission: 'SCHOOL_READ_WEB' },
            { title: 'Houses', icon: 'mdi-home-circle', link: {name: 'dashboard.houses'}, permission: 'HOUSE_READ_WEB' },
            { title: 'Discount Offers', icon: 'mdi-currency-usd-off', link: {name: 'dashboard.discount_offers'} },
            { title: 'Packs', icon: 'mdi-package-variant', link: {name: 'dashboard.packs'} },
            { title: 'Orders', icon: 'mdi-package', link: {name: 'dashboard.orders.index'} },
        ],
        reportNavItems: [
            { title: 'Vouchers Report', icon: 'mdi-ticket-percent-outline', link: {name: 'dashboard.vouchers'}, permission: 'VOUCHER_READ_WEB' },
            { title: 'Gift Cards Report', icon: 'mdi-ticket-percent-outline', link: {name: 'dashboard.gift-cards'}, permission: 'VOUCHER_READ_WEB' },
            { title: 'Gift Card Transactions Report', icon: 'mdi-ticket-percent-outline', link: {name: 'dashboard.gift-card-transactions'}, permission: 'VOUCHER_READ_WEB' },
        ],
    },
    mutations: {
        [MUTATION_TYPES.ADD_ALERT](state, value) {
            state.alerts.push(value);
        },
        [MUTATION_TYPES.REMOVE_ALERT](state, alertUuid) {
            state.alerts = state.alerts.filter((alert) => {
                return alert.uuid !== alertUuid;
            });
        },
    },
    actions: {
        [ACTION_TYPES.ADD_ALERT.NAME]({ commit }, alertData) {
            const newAlert = Object.assign({
                uuid: uuidv4(),
                visible: true,
                timeout: 2000,
            }, alertData);
            commit(MUTATION_TYPES.ADD_ALERT, newAlert);
            setTimeout(() => {
                commit(MUTATION_TYPES.REMOVE_ALERT, newAlert.uuid);
            }, newAlert.timeout + 500)
        },
    },
});
